import React, {useEffect} from "react";
import { Routes, Route, Navigate , useLocation} from "react-router-dom";
import {useDispatch, useSelector } from 'react-redux';
import {ROLES} from './constants';
import {getAuth, onAuthStateChanged,} from 'firebase/auth';
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import DashboardPage from './pages/DashboardPage';
import DashboardIndex from './pages/DashboardIndex';
import AddorEditProperty from './components/dashboard/AddorEditProperty';
import AllProperties from './components/dashboard/AllProperties';
import Listed from './components/dashboard/Listed';
import Sold from './components/dashboard/Sold';
import RealtorLeads from "./components/dashboard/RealtorLeads";
import Loading from './components/common/Loading';

import { 
  selectIsLoggedIn ,
  setLoggedInUserData,
  userAuthenticated ,
  logoutUser,
  selectUserData,
} from "./store/user";
import Profile from "./pages/Profile";
import Privacy from "./components/Privacy";

export const LOGIN_PAGE_ROUTE = "/login";
export const SIGNUP_PAGE_ROUTE = "/signup";
export const HOME_PAGE_ROUTE = "/";

export const DASHBOARD_PAGE_ROUTE = "/dashboard";
export const ADD_PROPERTY_ROUTE = "/dashboard/add";
export const EDIT_PROPERTY_ROUTE = "/dashboard/edit/:id";
export const ALL_PROPERTIES_ROUTE = "/dashboard/properties";
export const LISTED_PROPERTY_ROUTE = "/dashboard/listed";
export const SOLD_PROPERTY_ROUTE = "/dashboard/sold";
export const PROFILE_PAGE_ROUTE = "/dashboard/profile"
export const LEADS_ROUTE = "/dashboard/your_leads"
export const PRIVACY_POLICY_ROUTE = "/privacy_policy"

export const AppRoutes = () => {

    const authentication = getAuth();
    let dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn)

    useEffect(()=>{
      if (sessionStorage.getItem("userId")) {
        console.log('AUTH USER BECAUSE TOKEN EXISTS');
        dispatch(userAuthenticated())
      }else{
        dispatch(logoutUser())
      }

      onAuthStateChanged(authentication, (user) => {
        if (user) {
          console.log(user)
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          console.log(sessionStorage.getItem('userId')) 
          
          authentication.currentUser.getIdTokenResult().then((idTokenResult) => {
            if( uid == sessionStorage.getItem("userId") || !sessionStorage.getItem('userId') ){
              dispatch(setLoggedInUserData({ user, idTokenResult}));
            }
         })
         .catch((error) => {
           console.log(error);
         });
         
          // ...
        } else {
          // User is signed out
          // ...
          dispatch(logoutUser())
        }
      });  
    },[])
  
    return (

    <Routes>
      <Route exact path={LOGIN_PAGE_ROUTE} element={<LoginPage/>}/> 
      <Route exact path={HOME_PAGE_ROUTE} element={<HomePage/>}/> 
      <Route exact path={PRIVACY_POLICY_ROUTE} element={<Privacy/>}/> 
      <Route
        path={DASHBOARD_PAGE_ROUTE}
        element={
        <PrivateRoute roles={[ROLES.Admin, ROLES.Realtor]}>
           <DashboardPage />
        </PrivateRoute>
        }>
          <Route 
            index 
            element={ 
              <PrivateRoute roles={[ROLES.Admin, ROLES.Realtor]}> 
                <DashboardIndex /> 
              </PrivateRoute>} 
            />
            <Route 
            path = {PROFILE_PAGE_ROUTE} 
            element={ 
              <PrivateRoute roles={[ROLES.Admin, ROLES.Realtor]}> 
                <Profile/> 
              </PrivateRoute>} 
            /> 
            <Route  
            path={ADD_PROPERTY_ROUTE} 
            element={ 
              <PrivateRoute roles={[ROLES.Admin]}> 
                <AddorEditProperty /> 
              </PrivateRoute>} 
            />  
            <Route 
            path={EDIT_PROPERTY_ROUTE}
            element={
              <PrivateRoute roles={[ROLES.Admin]}>
                <AddorEditProperty />
              </PrivateRoute>} 
            />
            <Route 
            path={ALL_PROPERTIES_ROUTE}
            element={
              <PrivateRoute roles={[ROLES.Admin]}>
                <AllProperties />
              </PrivateRoute>} 
            />
            <Route 
            path={LISTED_PROPERTY_ROUTE}
            element={
              <PrivateRoute roles={[ROLES.Admin]}>
                <Listed />
              </PrivateRoute>} 
            />
            <Route 
            path={SOLD_PROPERTY_ROUTE}
            element={
              <PrivateRoute roles={[ROLES.Admin]}>
                <Sold />
              </PrivateRoute>}
            /> 
            <Route 
            path={LEADS_ROUTE}
            element={
              <PrivateRoute roles={[ROLES.Realtor]}>
                <RealtorLeads />
              </PrivateRoute>}
            />
          </Route>
          

      {/* <Route
          path={HOME_PAGE_ROUTE}
          element={
          <PrivateRoute roles={[ROLE.Admin, ROLE.Contractor, ROLE.PropertyManager, ROLE.VirtualRealtor, ROLE.Management, ROLE.InventoryManager]}>
             <DashboardPage />
          </PrivateRoute>
          } /> */}
        <Route path={"*"} element={ <Navigate replace to={ isLoggedIn ? HOME_PAGE_ROUTE : DASHBOARD_PAGE_ROUTE } />  }/>
    </Routes>)
  };
  
  

function PrivateRoute({ roles, children }) {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const user  = useSelector(selectUserData);

  let role;
  if(user?.admin){
    role = user?.admin && 'admin' 
  }else{
    role = user?.realtor && 'realtor' 
  }
  
  console.log(user?.admin)
  const userHasRequiredRole = user && roles.includes(role) ? true : false;

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
  if(isLoggedIn && !user){
    return <div style={
      {height:'100vh',
      display:'flex',
      justifyContent:'center',
      alignItems:'center'
    }}> <Loading/></div>
  }
 
  if (isLoggedIn && !userHasRequiredRole) {
    return <div> You are not authorized for this page. </div> // build your won access denied page (sth like 404)
  }

  return children
}