import { APP_COLORS } from "../styles";
import Dialog from './common/Dialogue';
import Footer from "./Footer";
import Header from "./Header";

export default function Privacy({ show }) {
  let isMobile = window.innerWidth < 500;
  const styles = {
    container: {
      textAlign: 'left',
      padding: '30px',
      backgroundColor: '#fff',
      color: '#333',
      fontFamily: 'Arial, sans-serif',
      lineHeight: 1.6,
    },
    title: {
      fontSize: '2.5rem',
      marginBottom: '20px',
      color: '#2c3e50',
    },
    sectionTitle: {
      fontSize: '1.8rem',
      marginTop: '40px',
      marginBottom: '10px',
      color: '#34495e',
    },
    sectionSubTitle: {
      fontSize: '1.2rem',
      marginTop: '20px',
      marginBottom: '5px',
      color: '#34495e',
    },
    paragraph: {
      fontSize: '1rem',
      marginBottom: '20px',
    },
    list: {
      marginLeft: '20px',
      marginBottom: '20px',
    },
    listItem: {
      marginBottom: '10px',
    },
  };
  return (
    <>
    <Header />
      <section style={styles.container}>
        <h1 style={styles.title}>Privacy Policy</h1>
        <p style={styles.paragraph}>
          This Privacy Policy (“Policy”) describes and governs the information collection, use, and sharing practices of Home Dealers LLC and our affiliates or subsidiaries (“we,” “us,” and “our”) with respect to your use of Home Dealers’s websites, products, tools, promotions, and any other services that reference this Policy (collectively, the “Services”).
        </p>
        <p style={styles.paragraph}>
          We reserve the right to make changes to this Privacy Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Privacy Policy. Any changes or modifications will be effective immediately upon posting the updated Privacy Policy on the Site, and you waive the right to receive specific notice of each such change or modification.
        </p>
        <p style={styles.paragraph}>
          Before you use or submit any information through or in connection with the Services, please carefully review this Policy. By using any part of the Services, regardless of how you access the Services, you consent to the collection, use, and disclosure of your information as further outlined in this Policy. IF YOU DO NOT AGREE TO THIS OUR POLICIES, PLEASE DO NOT USE THE SERVICES. We will continue to evaluate this Policy as we update and expand the Services and our offerings, and we may make changes to the Policy accordingly. Any changes will be posted here and you should check our website periodically for updates. If we make material changes to this Policy, we will provide you with notice as required by law. Your continued use of the Services will signify acceptance of the terms of the updated Policy.
        </p>

        <h2 style={styles.sectionTitle}>Information We Collect</h2>
        <p style={styles.paragraph}>
          We collect information in multiple ways, including when you provide information directly to us, and when we passively collect information from you, such as from your browser or device.
        </p>
        <h3 style={styles.sectionSubTitle} >
          Information You Provide Directly To Us
        </h3>
        <p style={styles.paragraph}>
          We may collect information from you in a variety of ways, such as when you:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>Respond to our communications or communicate with us;</li>
          <li style={styles.listItem}>Register for an account;</li>
          <li style={styles.listItem}>Use certain features that require your information to function;</li>
          <li style={styles.listItem}>Fill out a survey;</li>
          <li style={styles.listItem}>Provide us with Feedback;</li>
          <li style={styles.listItem}>Request certain features (e.g., newsletters, updates, and other products); or</li>
          <li style={styles.listItem}>Post User Content, including comments, to or on any of our Services.</li>
        </ul>
        <p style={styles.paragraph}>
          The information you provide directly to us may include, but is not limited to:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>(i) name; (ii) email address; (iii) physical addresses (e.g. your place of work); (iv) phone number; (v) birthdate; (vi) details about properties you are interested in and your preferences related to home searches (vii) photographs and audio/video content; and (viii) financial information.</li>
        </ul>
        <h3 style={styles.sectionSubTitle} >
          Information that is Passively or Automatically Collected
        </h3>
        <p style={styles.paragraph}>
          <strong>i. Device/Usage Information</strong>
        </p>
        <p style={styles.paragraph}>
          We may automatically collect certain information about the computer or devices (including mobile devices or tablets) you use to access the Services. As described further below, we may collect and analyze information such as (a) IP addresses, geolocation information, unique device identifiers, IMEI and TCP/IP address, and other information about your computer or device(s), browser types, browser language, operating system, mobile device carrier information, the state or country from which you accessed the Services; and (b) information related to the ways in which you interact with the Services, such as: referring and exit web pages and URLs, platform type, the number of clicks, domain names, landing pages, pages, and content viewed and the order of those pages, statistical information about the use of the Services, the amount of time spent on particular pages, the date and time you used the Services, the frequency of your use of the Services, error logs, and other similar information. As described further below, we may use third-party analytics providers and technologies, including cookies and similar tools, to assist in collecting this information.
        </p>
        <p style={styles.paragraph}>
          <strong>ii. Cookies and Other Electronic Technologies</strong>
        </p>
        <p style={styles.paragraph}>
          We may also collect data about your use of the Services through the use of Internet server logs, cookies, and/or tracking pixels. A web server log is a file where website activity is stored. A cookie is a small text file that is placed on your computer when you visit a website, that enables us to: (i) recognize your computer; (ii) store your preferences and settings; (iii) understand the web pages of the Services you have visited; (iv) enhance your user experience by delivering content and advertisements specific to your inferred interests; (v) perform searches and analytics; and (vi) assist with security administrative functions. Some cookies are placed in your browser cache while those associated with Flash technologies are stored with your Adobe Flash Player files. Tracking pixels (sometimes referred to as web beacons or clear GIFs) are tiny electronic tags with a unique identifier embedded in websites, online ads, and/or email, and that are designed to provide useful information like ad impressions or clicks, measure the popularity of the Services and associated advertising, and to access user cookies. We also may include Web beacons in email messages, newsletters, and other electronic communications to determine whether the message has been opened and for other analytics, personalization, and advertising. As we adopt additional technologies, we may also gather additional information through other methods.
        </p>
        <p style={styles.paragraph}>
          Please note that you can change your settings to notify you when a cookie is being set or updated, or to block cookies altogether. Please consult the “Help” section of your browser for more information (e.g., Internet Explorer; Google Chrome; Mozilla Firefox; or Apple Safari). You can also manage the use of Flash technologies, including cookies and local storage objects with the Flash management tools available on Adobe’s website. Please note that by blocking any or all cookies, you may not have access to certain features or offerings of the Services.
        </p>
        <p style={styles.paragraph}>
          <strong>
            iii. Location Information
          </strong>
        </p>
        <p style={styles.paragraph}>
          We may collect different types of information about your location, including general information (e.g., IP address, zip code) and more specific information (e.g., GPS-based functionality on mobile devices used to access the Services), and may use that information to customize the Services with location-based information and features. We may use such information to improve the Services, including providing you with location-based features (e.g. to identify listings that may interest you). To deliver customized content and advertising, we may share your location information with our Members, vendors, or advertisers. If you access the Services through a mobile device and you do not want your device to provide us with location-tracking information, you can disable the GPS or other location-tracking functions on your device, provided your device allows you to do this. See your device manufacturer’s instructions for further details. If you disable certain functions, you may be unable to use certain parts of the Services.
        </p>

        <p style={styles.paragraph}>
          <strong>
            iv. Information from Third Parties
          </strong>
        </p>
        
        <p style={styles.paragraph}>
          We may also collect information about you or others through non-affiliated third parties. For example, to the extent permitted by law, we may, in our sole discretion, ask for and collect supplemental information from third parties, such as information to verify your identity or trustworthiness, or for other fraud or safety protection purposes. We may combine information that we collect from you through the Services with information that we obtain from such third parties and information derived from any other products or services we provide.
        </p>

        <h3 style={styles.sectionSubTitle} >
          Collection of information across personal devices
        </h3>
        <p style={styles.paragraph}>
          Sometimes we (or our service providers) may use the information we collect – for instance, log-in credentials, IP addresses, hashed email addresses, and unique mobile device identifiers – to locate or try to locate the same unique users across multiple browsers or devices (such as smartphones, tablets, or computers), or work with providers that do this, in order to tailor content, features, and advertising better, and provide you with a seamless experience across the devices you use to access the Services.
        </p>

        <h2 style={styles.sectionTitle}>How We Use Your Information</h2>
        <p style={styles.paragraph}>
          We may use the information we collect from and about you for the following purposes:
        </p>
        <ul style={styles.list}>
          <li style={styles.listItem}>For the purposes for which you provided it;</li>
          <li style={styles.listItem}>To recognize and authenticate you on the Services;</li>
          <li style={styles.listItem}>To initiate or to provide the features, services, and products available through the Services;</li>
          <li style={styles.listItem}>To process transactions and send related information such as confirmations and invoices;</li>
          <li style={styles.listItem}>To send you information about your relationship or transactions with us, technical notices, updates, account or security alerts, or other communications, such as newsletters to which you have subscribed;</li>
          <li style={styles.listItem}>To contact you with information, surveys, or services that we believe may be of interest to you both regarding our products and services.</li>
          <li style={styles.listItem}>To process and respond to your inquiries;</li>
          <li style={styles.listItem}>For internal research and reporting;</li>
          <li style={styles.listItem}>To monitor and analyze trends, usage, and activities in connection with our Services and for statistical purposes;</li>
          <li style={styles.listItem}>To measure, improve, and understand the effectiveness of content, features, and Services, or develop new Services;</li>
          <li style={styles.listItem}>To personalize the content and advertising that you see on the Services or on other websites;</li>
          <li style={styles.listItem}>To facilitate the sending of SMS messages that provide information to you in response to your inquiries and on behalf of an agent you are communicating with;</li>
          <li style={styles.listItem}>With your consent, to call or send you SMS messages regarding our Services as well as your relationship with us or offers or services that may interest you;</li>
          <li style={styles.listItem}>To detect, investigate, and prevent fraud and other illegal activities;</li>
          <li style={styles.listItem}>To enforce the legal terms that govern your use of the Services; and</li>
          <li style={styles.listItem}>To administer and troubleshoot the Services.</li>
        </ul>
        <h3>Consent</h3>

        <p style={{...styles.paragraph, fontStyle:'italic', margin:'20px 30px'}}>
          You are authorizing Home Dealers and its corporate sibling partner entities to deliver or cause to be delivered to you telemarketing promotions for products or services in addition to those about which you are applying, but that may be of interest to you using an automatic telephone dialing system or an artificial or prerecorded voice and text messages to the phone numbers you provided above. You are not required to sign this agreement as a condition of purchasing any property, goods, or services.
        </p>
        <p style={styles.paragraph}>
          Consent varies based on the types of conversations the company has with its clients. (Conversational, Informational, Promotional, etc.) and on the nature of the conversation (Transactional or Promotional).
        </p>
        <p style={styles.paragraph}>
          Conversational SMS/text messaging is a one-on-one two-way conversation between the company and its existing customers or known contacts with an existing business relationship.
        </p>
        <p style={styles.paragraph}>
          If a customer texts us first and we respond quickly with a single message, then it is likely conversational. As long as the company responds with relevant information, then verbal and written permission is not required. This is called implied consent.
        </p>
        
        
        <h3>We Ensure Your Privacy and Data Protection</h3>
        <p style={styles.paragraph}>
         We prioritize your privacy and the protection of your personal data. <strong>We Do Not</strong> share personal information, including phone numbers, with third parties, affiliates, or partners for marketing or promotional purposes. Your consent to receive text messages from Home Dealers is exclusively for our use and cannot be transferred to any other party or business. For instance, if you consent to receive texts from us, this consent cannot be used by any other business.
        </p>

      </section>
      <section>
      <div style={styles.container}>
        <h1 style={styles.title}>Communication Policy</h1>
        <p style={styles.note}>
          This Communication Policy outlines our approach to communication methods and our compliance with relevant regulations, including the Telephone Consumer Protection Act (TCPA). By using our services, you agree to the terms described in this policy. Please review this policy carefully. If you do not agree with these terms, please refrain from using our services.
        </p>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>1. Communication Channels</h2>
          <p style={styles.paragraph}>
            We may communicate with you through various channels including SMS, email, and messaging apps such as Apple’s Game Center, Facebook, Google, Instagram, Pinterest, Twitter, Whatsapp, Viber, and Zoom. These communications may be on behalf of Home Dealers or our affiliates.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>2. Authorization for Communication</h2>
          <p style={styles.paragraph}>
            By providing your contact information, you authorize Home Dealers and its affiliates to send you telemarketing promotions and other communications using an automatic telephone dialing system or prerecorded voice and text messages. This authorization is not a condition for purchasing any property, goods, or services.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>3. Changes to Communication Policy</h2>
          <p style={styles.paragraph}>
            We reserve the right to modify this Communication Policy at any time. Updates will be posted on our website with an updated "Last Updated" date. Continued use of our services after changes indicates acceptance of the revised policy.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>4. Service Availability</h2>
          <p style={styles.paragraph}>
            Our communication services are offered on an “as is” basis. They may not be available in all areas or through all carriers, and Home Dealers may modify or discontinue these services without notice. We are not responsible for any technical failures or delays and may cease communication at our discretion.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>5. Text Alerts</h2>
          <p style={styles.paragraph}>
            If you opt into text alerts, you will receive updates related to your orders and product delivery. Message frequency and content will vary. While Home Dealers does not charge for text alerts, standard message and data rates from your carrier may apply. You may opt out of text alerts at any time by replying "STOP" to any text message.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>6. Types of Messages</h2>
          <p style={styles.paragraph}>
            <strong>Conversational SMS/Text Messaging:</strong> One-on-one messages with existing customers or known contacts. Verbal and written permission is generally not required, known as implied consent.
          </p>
          <p style={styles.paragraph}>
            <strong>Informational SMS/Text Messaging:</strong> Messages providing information where you have given express permission to be contacted. This can be through text opt-in, forms, websites, or verbally.
          </p>
          <p style={styles.paragraph}>
            <strong>Promotional SMS/Text Messaging:</strong> Messages that promote or market our services. You must provide express consent to receive these messages.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>7. Text Message Call-to-Action (CTA)</h2>
          <p style={styles.paragraph}>
            We may include CTAs in our text messages to prompt specific actions, such as paying invoices or visiting websites. Message and data rates may apply based on your mobile phone contract. You may opt out of receiving these messages by texting "STOP" or "UNSUBSCRIBE."
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>8. Call Recording</h2>
          <p style={styles.paragraph}>
            Home Dealers may record phone calls for quality monitoring, training, compliance, and safeguarding purposes. Personal data collected will be processed in accordance with relevant legislation, including GDPR. Calls will not be monitored unless necessary for compliance, quality checks, or other stated purposes. By continuing a call, you consent to recording.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>9. Facebook and Other Platforms</h2>
          <p style={styles.paragraph}>
            We may access and use information from your social media accounts if you connect them to our services. This may include basic account information and permissions you grant.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>10. Data Collection from Contests and Surveys</h2>
          <p style={styles.paragraph}>
            We collect personal information from contests, giveaways, and surveys. This data may be used as described in our policies.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>11. Legal Compliance and Third Parties</h2>
          <p style={styles.paragraph}>
            We may share your information as required by law or to protect our rights.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>12. Interactions with Other Users</h2>
          <p style={styles.paragraph}>
            Your interactions with other users may be visible to them, including your name and activity descriptions.
          </p>
        </div>

        {/* <div style={styles.section}>
          <h2 style={styles.subtitle}>13. Online Postings and Advertising</h2>
          <p style={styles.paragraph}>
            Your posts on our site or app may be publicly accessible. We use third-party advertising companies to serve ads based on your visit history.
          </p>
        </div> */}

        {/* <div style={styles.section}>
          <h2 style={styles.subtitle}>14. Affiliates and Business Partners</h2>
          <p style={styles.paragraph}>
            We may share your information with affiliates and business partners for offering products or services. Affiliates are required to honor our Communication Policy.
          </p>
        </div> */}

        <div style={styles.section}>
          <h2 style={styles.subtitle}>13. Sale or Bankruptcy</h2>
          <p style={styles.paragraph}>
            In the event of a business reorganization, merger, or acquisition, your information may be transferred to the new entity. If we go out of business or enter bankruptcy, your data may be transferred as part of the assets.
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>14. Opt-Out</h2>
          <p style={styles.paragraph}>
            To stop receiving communications from us, you may:
          </p>

          <ul style={styles.list}>
            <li style={styles.listItem}>Reply STOP to opt out of further messaging.</li>
            <li style={styles.listItem}>Contact us directly using the contact information below.</li>
          </ul>
          {/* <p style={styles.paragraph}>
          For third-party communications, you must contact the third party directly.
          </p> */}
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>15. Contact Us</h2>
          <p style={styles.paragraph}>
            For questions about this Communication Policy, please contact us at:
            <div style={styles.contactInfo}>Email: sales@home-dealers.com</div>
          </p>
        </div>

        <div style={styles.section}>
          <h2 style={styles.subtitle}>16. Dispute Resolution</h2>
          <p style={styles.paragraph}>
            All disputes related to this policy or our services will be resolved through binding arbitration rather than court trials. Class actions are not permitted. The arbitration will be conducted by the American Arbitration Association under its Consumer Arbitration Rules.
          </p>
        </div>
      </div>
      </section>
      <Footer/>
      </>
  )
}

const styles={
  title:{
      color:'black',
    },
    content:{
      color:'#444',
      lineHeight:'1.3',
      paddingTop:'10px'
    }
}